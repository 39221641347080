import revive_payload_client_0M7gAwvpbP from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_cAclS3pt0c from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_pyBbRTvCL9 from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_bNqRx9Y0tM from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2SFGcsYFM4 from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Lvl6xuSWUN from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_WTaAGlfYdz from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_CiiyNCsn0I from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import switch_locale_path_ssr_AYSHXaDw43 from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_q6JQbKLPGM from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_NYyN9hFxRZ from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/apps/site-factory/.nuxt/components.plugin.mjs";
import prefetch_client_wkKDeNouiX from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.10.1_db0@0.3.1_eslint@9.24.0_jiti@2.4.2__ior_6lqquha72cbxkh4mkesexa4soq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/apps/site-factory/.nuxt/formkitPlugin.mjs";
import plugin_T87aqnDutO from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.2.6_@types+node@22.10.1_jiti@2.4.2_terser@5.39.0_tsx@_wxyhewnkertvtnw7afskdka2ea/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_TWJD1V70hH from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/nuxt-graphql-client@0.2.43_@parcel+watcher@2.5.1_@types+node@22.10.1_graphql-sock@1.0.1_graph_zumua3klhrxdhnxzg2ukira4vi/node_modules/nuxt-graphql-client/dist/runtime/plugin.js";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/layers/base/plugins/sentry.client.ts";
import widget_mode_X6BjB4n2GO from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/layers/base/plugins/widget-mode.ts";
import ssg_detect_nKBNdcrgPZ from "/home/forge/deployments/sitefactory/live/2025-04-11_12-01-53_83ffe95/node_modules/.pnpm/@nuxtjs+i18n@9.5.2_@vue+compiler-dom@3.5.13_eslint@9.24.0_jiti@2.4.2__magicast@0.3.5_rollup@4_wzzchgip5t3bcppes5kopq75s4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_0M7gAwvpbP,
  unhead_cAclS3pt0c,
  router_pyBbRTvCL9,
  payload_client_bNqRx9Y0tM,
  navigation_repaint_client_2SFGcsYFM4,
  check_outdated_build_client_Lvl6xuSWUN,
  view_transitions_client_WTaAGlfYdz,
  chunk_reload_client_CiiyNCsn0I,
  switch_locale_path_ssr_AYSHXaDw43,
  route_locale_detect_q6JQbKLPGM,
  i18n_NYyN9hFxRZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_wkKDeNouiX,
  formkitPlugin_pZqjah0RUG,
  plugin_T87aqnDutO,
  plugin_TWJD1V70hH,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV,
  widget_mode_X6BjB4n2GO,
  ssg_detect_nKBNdcrgPZ
]